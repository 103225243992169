const slideInterval=8000;
var slideCounter=0;
var currentSlide=0;
const zIndex_screen=210;
const zIndex_slide=110;
const screens= $('.hp-banner_screenImage');
const slideImages= $('.hp-banner_slideImage');
var tlArray=new Array();

TweenLite.ticker.useRAF(false);
TweenMax.lagSmoothing(0);

function init_HP_banner() {
    $( ".hp-banner__slideImage" ).each(function( index ) {
      var tl= new TimelineMax({ paused:true});
      const screenImage =".js-screenImage-"+index;
      tl.from($(this), 2, {opacity:0, delay:0, ease:Power4.easeOut});
      tl.from(screenImage, 2, {opacity:0, ease:Power4.easeIn}, "-=2");      
      tlArray.push(tl);
     
  })
TweenMax.to('.hp-banner__screenShadow', 1, { opacity: 1});
 tlArray[0].progress(100); 
} 

function setDepth(){
    tlArray[slideCounter].progress(0);
    $('.hp-banner__slideImage').css('z-index',100);
    $('.hp-banner__screenImage').css('z-index',200);

    const slideImageC=".js-slideImage-"+currentSlide;
    const screenImageC=".js-screenImage-"+currentSlide;
    const slideImage=".js-slideImage-"+slideCounter;
    const screenImage =".js-screenImage-"+slideCounter;

    $(slideImageC).css('z-index',zIndex_slide-1);
    $(screenImageC).css('z-index',zIndex_screen-1);

    $(slideImage).css('z-index',zIndex_slide);
    $(screenImage).css('z-index',zIndex_screen);

    tlArray[slideCounter].play();
}

var timerSlide = function(){
    currentSlide=slideCounter;
    slideCounter++
    if (slideCounter>2){
        currentSlide=2
        slideCounter=0;
    }
    setDepth()
};

setInterval(timerSlide, slideInterval);



init_HP_banner();
